import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
})
export class AdressComponent implements OnInit {

  positionMap = {
    street: "Av. Romás Rabêlo e Silva",
    num: "310",
    city: "São Paulo"
  };
  mapsURL = `https://maps.google.com/maps?q=${this.positionMap.street}%20${this.positionMap.num}%20%${this.positionMap.city}&t=&z=20&ie=UTF8&iwloc=&output=embed`;

  constructor(
               ) { }

  ngOnInit() {
  }

}
