import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements OnInit {

  formComent: FormGroup;
  formBusca: FormGroup;
  
  imagensProduto: any[] = [];  
  produtoNome: string = "";
  produtoDetalhes: string = "";

  composicaoProduto: any[] = [];  
  caracteristicasProduto: any[] = []; 
  coresProduto: any[] = [];   

  constructor(private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {

      if (params.id != undefined){
                 
        if (params.id == "avental"){          
          this.produtoNome = "Avental Descartável";
          this.produtoDetalhes = "Os Aventais Descartáveis foram projetados especificamente para a proteção de pacientes e equipe de saúde médico – hospitalar, odontológica, alimentícia, farmacêutica, laboratorial e clínicas de estéticas, com conforto e confiança.";

          this.imagensProduto.push({url: "assets/img/Services/avental1.jpg"});
          this.imagensProduto.push({url: "assets/img/Services/avental2.jpg"});
          this.imagensProduto.push({url: "assets/img/Services/avental3.jpg"});
          this.imagensProduto.push({url: "assets/img/Services/avental4.jpg"});
          
          this.composicaoProduto.push({composicao: "TNT (Tecido Não Texturizado): 100% Polipropileno; gramatura de 20/30/40/50/60."});
          this.composicaoProduto.push({composicao: "TNT (Tecido Não Texturizado) 100% de Polipropileno gramatura de 20/30/40/50/60 com lâmina de polietileno (são os impermeáveis)."});
          this.composicaoProduto.push({composicao: "Botões de pressão, adesivo quicklon (velcro), filamento de poliéster texturizados e fios elásticos (60% elastodieno e 40% poliéster)."});

          this.caracteristicasProduto.push({descricao: "São descartáveis;"});
          this.caracteristicasProduto.push({descricao: "Garante resistência à umidade, à abrasão e à ação de fungos e bactérias;"});
          this.caracteristicasProduto.push({descricao: "Elevada inércia química;"});
          this.caracteristicasProduto.push({descricao: "Leveza;"});
          this.caracteristicasProduto.push({descricao: "Não estéril;"});
          this.caracteristicasProduto.push({descricao: "Atóxica, não inflamável e esterilizável;"});
          this.caracteristicasProduto.push({descricao: "Não absorve líquidos (como água, corantes, sangue, etc...) e apresenta impermeabilidade de 80%"});

          this.coresProduto.push({cor: "Branca"});
          this.coresProduto.push({cor: "Azul"});
          this.coresProduto.push({cor: "Verde"});
          this.coresProduto.push({cor: "Vermelha"});
          this.coresProduto.push({cor: "Os Impermeáveis são apresentados na cor Branca"});

        }
        
      
      }
      

    });

  }

}
